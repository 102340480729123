import React, { useState, useEffect } from "react";
import Pretourpagecrd from "../Component/Pretourpagecrd";
import axios from "axios";
import GridLoader from "../Loaders/GridLoader";

const Pretourpage = () => {
  const [pretour, setPretour] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}pretour/get`,
    })
      .then((res) => {
        let response = res?.data?.data?.pretours?.data;
        setPretour(response);

        setImagePath(res?.data?.data?.main_image_base_url);
        setLoading(false);
      })
      .catch((err) => {
        console.log("this is error", err);
        setLoading(false);
      });
  }, []);

  console.log("pretour data pass to the state", pretour);
  console.log("pretour image path pass to the state", imagePath);

  return (
    <>
      <div className="pagesection">
        <div className="container">
          <div className="sub-section text-center">
            <h5 className="title m-0">Pre-Tours</h5>
          </div>

          {loading ? (
            <GridLoader item={2} row={3} />
          ) : (
            <div className="row">
              {pretour.map((get, keys) => (
                <>
                  <div className="col-lg-6 col-md-6">
                    <Pretourpagecrd
                      data={get}
                      imageurl={imagePath}
                      type="pre"
                    />
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Pretourpage;
