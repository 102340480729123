import { fireSuccessToaster } from "./alertProvider";

export const AddToCart = (data) => {
  let myCart = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  let found = myCart.find((item) => {
    if (item.id == data.id && item.type == data.type) {
      return item;
    }
  });

  if (found) {
    found.quantity = parseInt(found.quantity) + 1;
  } else {
    myCart.push({
      image: data.image,
      title: data.title,
      quantity: 1,
      price: data.price,
      type: data.type,
      id: data.id,
    });
  }

  localStorage.setItem("rtn-cart", JSON.stringify(myCart));
  fireSuccessToaster("Item added to cart");
  window.dispatchEvent(new Event("cart-change"));
};

export const GetCartItems = () => {
  let cartItems = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  return cartItems;
};

export const removeCartItem = (id) => {
  let cartItems = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  let temp = cartItems.filter((item, index) => {
    if (index != id) {
      return item;
    }
  });

  // console.log("Temp is", temp);

  localStorage.setItem("rtn-cart", JSON.stringify(temp));
  window.dispatchEvent(new Event("cart-change"));

  // setReloader(!reloader);
};

export const increaseItem = (id, type, reloader, setReloader) => {
  let cartItems = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  let myItem = cartItems.find((item, index) => {
    if (item.id == id && item.type == type) {
      return item;
    }
  });

  myItem.quantity = parseInt(myItem.quantity) + 1;
  localStorage.setItem("rtn-cart", JSON.stringify(cartItems));
  setReloader(!reloader);
  window.dispatchEvent(new Event("cart-change"));
};

export const decreaseItem = (id, type, reloader, setReloader) => {
  let cartItems = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  let myItem = cartItems.find((item, index) => {
    if (item.id == id && item.type == type) {
      return item;
    }
  });

  if (myItem.quantity > 1) {
    myItem.quantity = parseInt(myItem.quantity) - 1;
  }
  localStorage.setItem("rtn-cart", JSON.stringify(cartItems));
  setReloader(!reloader);
  window.dispatchEvent(new Event("cart-change"));
};

export const getItemCount = (id) => {
  let cartItems = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  console.log("My Cart", cartItems);

  let myItem = cartItems.filter((item, index) => {
    if (id == index) {
      return item;
    }
  });

  console.log(myItem);

  return myItem?.[0]?.quantity || 1;
};

export const getCart = () => {
  let cartItems = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  let total = cartItems.reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);

  console.log("Totla is", total);

  return total;
};

export const getSubTotal = (id) => {
  let cartItems = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  let myItem = cartItems.find((item, index) => {
    if (index == id) {
      return item;
    }
  });

  return myItem?.quantity * myItem?.price;
};

export const getTotalCartAmount = () => {
  let cartItems = JSON.parse(localStorage.getItem("rtn-cart")) || [];

  let total = 0;
  for (let i = 0; i < cartItems.length; i++) {
    total = total + cartItems[i]?.price * cartItems[i]?.quantity;
  }

  return total;
};
