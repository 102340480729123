import axios from "axios";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  fireErrorModal,
  fireErrorToaster,
  fireSuccessModal,
} from "../helpers/alertProvider";
import { GetCartItems } from "../helpers/CartHelper";
import { isEmailValid } from "../helpers/validator";
import Captcha from "../providers/Captcha";
import CheckoutForm from "../Component/CheckoutForm";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  association_name: "",
  club_name: "",
  meeting_type: [],
  diatary_requirements: "",
  visa_letter: "No",
  passport_number: "",
  upload_passport: "",
  couple_status: "single",
  partner_name: "",
  partner_passport_detail: "",
  salutation: "delegate",
  allergies: "",
  nationality: "",
};

const Checkoutpage = () => {
  const [checkoutData, setCheckData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [captchaStatus, setCaptchaStaus] = useState(false);
  const recaptchaRef = React.useRef();
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(false);
  const [status, setStatus] = useState("single");

  let cartItems = GetCartItems();

  const handleCheckoutData = (e) => {
    if (e.target.name === "upload_passport") {
      setCheckData({ ...checkoutData, upload_passport: e.target.files[0] });
    } else {
      setCheckData({ ...checkoutData, [e.target.name]: e.target.value });
    }
  };

  const handleMeeting = (e, data) => {
    let selected = [...checkoutData.meeting_type];
    if (selected.includes(data)) {
      selected = selected.filter((item) => item != data);
    } else {
      selected.push(data);
    }

    setCheckData({ ...checkoutData, meeting_type: selected });
  };

  // console.log(checkoutData);

  const placeOrder = () => {
    if (!accepted) {
      fireErrorToaster("Please accept terms and conditions to continue");
      return;
    }

    if (checkoutData.first_name === "") {
      fireErrorToaster("First Name is required");
      return;
    }

    if (checkoutData.last_name === "") {
      fireErrorToaster("Last Name is required");
      return;
    }

    if (checkoutData.last_name === "") {
      fireErrorToaster("Last Name is required");
      return;
    }

    if (checkoutData.phone === "") {
      fireErrorToaster("Phone is required");
      return;
    }

    if (checkoutData.email === "") {
      fireErrorToaster("Email is required");
      return;
    }

    if (!isEmailValid(checkoutData.email)) {
      fireErrorToaster("Please enter valid email");
      return;
    }

    if (checkoutData.couple_status === "couple") {
      if (checkoutData.partner_name === "") {
        fireErrorToaster("Please enter partner's name");
        return;
      }

      if (checkoutData.partner_passport_detail === "") {
        fireErrorToaster("Please enter partner's contact");
        return;
      }
    }

    if (checkoutData.association_name === "") {
      fireErrorToaster("Association Name is required");
      return;
    }

    if (checkoutData.club_name === "") {
      fireErrorToaster("Club Name is required");
      return;
    }

    if (checkoutData.meeting_type.length <= 0) {
      fireErrorToaster("Please select a meeting");
      return;
    }
    if (checkoutData.passport_number === "") {
      fireErrorToaster("Please enter your passport number");
      return;
    }

    if (captchaStatus == false) {
      fireErrorToaster("Please fill the captcha");
      return;
    }

    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}order/create-order`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        ...checkoutData,
        meeting_type: checkoutData.meeting_type.join(","),
        items: cartItems.map((item) => ({
          type: item.type,
          [item.type + "_id"]: item.id,
          quantity: item.quantity,
        })),
      },
    })
      .then((res) => {
        fireSuccessModal("Your order has been placed.");
        setLoading(false);
        navigate("/");

        setCheckData(initialState);
        localStorage.removeItem("rtn-cart");
        window.dispatchEvent(new Event("cart-change"));

        // console.log(res.data);
      })
      .catch((e) => {
        console.log(e.message);
        fireErrorModal(
          e?.response?.data?.message || e.message || "Faild to place your order"
        );
        setLoading(false);
      });
  };

  return (
    <>
      <div className="pagesection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="radio-group mb-4">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="Single"
                    value="single"
                    onChange={(e) => setStatus(e.target.value)}
                    checked={status === "single"}
                  />
                  <label for="Single">Single</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="Couple"
                    value="couple"
                    onChange={(e) => setStatus(e.target.value)}
                    checked={status === "couple"}
                  />
                  <label for="Couple">Couple</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="Couple with children (2)"
                    value="children"
                    onChange={(e) => setStatus(e.target.value)}
                    checked={status === "children"}
                  />
                  <label for="Couple with children (2)">
                    Couple with children (2)
                  </label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form">
                <CheckoutForm
                  loading={loading}
                  cartItems={cartItems}
                  checkoutData={checkoutData}
                  handleCheckoutData={handleCheckoutData}
                  handleMeeting={handleMeeting}
                  setCheckData={setCheckData}
                  heading="Personal Details"
                />
              </div>

              {(status === "couple" || status === "children") && (
                <div className="form mt-4">
                  <CheckoutForm
                    loading={loading}
                    cartItems={cartItems}
                    checkoutData={checkoutData}
                    handleCheckoutData={handleCheckoutData}
                    handleMeeting={handleMeeting}
                    setCheckData={setCheckData}
                    heading="Partner's Details"
                  />
                </div>
              )}

              {status === "children" && (
                <>
                  <div className="form-section mt-4">
                    <div className="sub-section m-0">
                      <h5 className="title m-0">Child 1 Details</h5>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 my-3 chek-out-sec">
                        <label for="exampleInputPassword1" class="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          class="form-control input-fld-sec"
                          id="exampleFormControlInput1"
                          name="dull_name"
                          // value={checkoutData.first_name}
                          // onChange={handleCheckoutData}
                        />
                      </div>
                      <div className="col-lg-12 my-3 chek-out-sec">
                        <label for="exampleInputPassword1" class="form-label">
                          Age
                        </label>
                        <input
                          type="text"
                          class="form-control input-fld-sec"
                          id="exampleFormControlInput1"
                          name="age"
                          onKeyDown={(event) => {
                            if (!/[0-9]/.test(event.key))
                              event.preventDefault();
                          }}
                          // value={checkoutData.first_name}
                          // onChange={handleCheckoutData}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-section mt-4">
                    <div className="sub-section m-0">
                      <h5 className="title m-0">Child 2 Details</h5>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 my-3 chek-out-sec">
                        <label for="exampleInputPassword1" class="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          class="form-control input-fld-sec"
                          id="exampleFormControlInput1"
                          name="dull_name"
                          // value={checkoutData.first_name}
                          // onChange={handleCheckoutData}
                        />
                      </div>
                      <div className="col-lg-12 my-3 chek-out-sec">
                        <label for="exampleInputPassword1" class="form-label">
                          Age
                        </label>
                        <input
                          type="text"
                          class="form-control input-fld-sec"
                          id="exampleFormControlInput1"
                          name="age"
                          onKeyDown={(event) => {
                            if (!/[0-9]/.test(event.key))
                              event.preventDefault();
                          }}
                          // value={checkoutData.first_name}
                          // onChange={handleCheckoutData}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-12 mt-4">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={accepted}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAccepted(true);
                      } else {
                        setAccepted(false);
                      }
                    }}
                  />{" "}
                  &nbsp;
                  <label htmlFor="terms">
                    {" "}
                    <small>
                      <span className="yellow"> I accept the</span>{" "}
                      <Link to="/terms&condition" target="_blank">
                        {" "}
                        <span className="red"> Terms & Conditions </span>{" "}
                      </Link>
                      <span style={{ color: "red" }}>*</span>{" "}
                    </small>
                  </label>
                </div>

                <div className="col-12 mt-4">
                  <Captcha
                    setCaptchaStaus={setCaptchaStaus}
                    recaptchaRef={recaptchaRef}
                  />
                </div>

                <div class="col-lg-12 my-3 chek-out-sec">
                  {/* <h6>
                    By clicking the button, you agree to the{" "}
                    <span>
                      <Link to="/cart/checkout/termsandcondations">Terms and Conditions</Link>
                    </span>
                  </h6> */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={placeOrder}
                    disabled={loading}
                  >
                    {" "}
                    {loading ? "Submitting..." : "Place Order"}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6 check-sid-sec">
              <div className="check-out-box">
                <h6>
                  {" "}
                  <i class="fa-solid fa-lock"></i> Cart Summary
                </h6>
                <table class="table check-tbl">
                  <tbody>
                    {cartItems?.map((item) => (
                      <tr>
                        <td>
                          {item?.title} x {item?.quantity}{" "}
                        </td>
                        <td>$ {item.quantity * item.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkoutpage;
